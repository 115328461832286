import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import PortableText from "react-portable-text";
import SEO from "../components/SEO";

const ContactStyles = styled.div`
  max-width: 450px;
  min-height: calc(100vh - 63px);
  padding-top: 107px;
  padding-left: calc((6.5% / 3));
  padding-right: 0;
  line-height: 1.3;
  p {
    margin: 0;
    margin-bottom: 35px;
  }
  a {
    text-decoration: none;
  }
  tr {
    margin-bottom: 1rem;
  }
  .cell {
    padding-right: 1rem;
  }
  @media (min-width: 2000px) {
    max-width: 700px;
    min-height: calc(100vh - 99px);
    padding-top: 143px;
  }
`;

export default function Contact({ data }) {
  const { email, phone, _rawAbout } = data.general;

  return (
    <>
      <SEO title="Contact" />
      <ContactStyles>
        <div>
          <PortableText
            content={_rawAbout}
            serializers={{
              // eslint-disable-next-line react/display-name
              link: ({ href, children }) => (
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  {children}
                </a>
              ),
            }}
          />
        </div>
        <table>
          <tbody>
            <tr>
              <td className="cell">M.</td>
              <td>
                <a href={`mailto:${email}`}>{email}</a>
              </td>
            </tr>
            <tr>
              <td className="cell">IG.</td>
              <td>
                <a
                  href="https://www.instagram.com/nathanceddia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  @nathanceddia
                </a>
              </td>
            </tr>
            <tr>
              <td className="cell">P.</td>
              <td>
                <a href={`tel:${phone.replace(/ /g, "")}`}>{phone}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </ContactStyles>
    </>
  );
}

export const query = graphql`
  query {
    general: sanityGeneral {
      _rawAbout
      email
      phone
    }
  }
`;
